import React from "react";
import { graphql, Link } from "gatsby";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import Layout from "../components/Layout";
import Heading from "../components/Heading";

import { Seo } from "../base/Seo";

export default function Categories({ data }) {
  const categories = data.post_categories.edges;

  return (
    <>
      <Seo title="Categories" />

      <Layout>
        <div className="section">
          <div className="page-header">
            <Heading tag="h1">
              Categories.
              <span className="paleTxt">
                Discover new and popular resources.
              </span>
            </Heading>
            <p>
              View all resources available. Sort and use filters to find what
              fits your needs
            </p>
          </div>
        </div>

        <div className="section">
          <div className="browse-archive">
            <div className="card-listing">
              {categories.map((category) => {
                return (
                  <div key={category.node.id}>
                    <Link to={category.node.fields.slug}>
                      {category.node.frontmatter.title}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="paginate">
            <span className="disabled">
              <FiChevronLeft />
            </span>
            <span className="active">1</span>
            <span>2</span>
            <span>
              <FiChevronRight />
            </span>
          </div>
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query CategoriesPageQuery {
    post_categories: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "post_category" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            template
            title
            subtitle
          }
        }
      }
    }
  }
`;
